import { Logger_Error } from "../../logger"

 let ut = 'c964af255f56da290419b605978b89db'

  let quoteapi = 'https://push2.eastmoney.com'


  const mapSortType = {
    name: 'f14',
    code: 'f12',
    close: "f2",
    change: "f4",
    changepercent: "f3",
    volume: "f5",
    amount: "f6",
    peration: "f9",
    marketvalue: "f20",
    listingdate: "f26", // f26
    issuepe: "f311",
    offeringtotalcount: "f310",
    offeringprice: "f309",
    gainsincelisting: "f304",
    offline_apply_upper: "f314",
    offline_apply_lower: "f313",
    issue_state: 'f308',
    issue_date: "f312",
    issue_num: "f310",
    issue_price: "f309",
    issue_name_abbr: "f14",
    issue_code: "f12",
    underlying_code: "f305",
    additionalprice: "f315",
    additionalquantity: "f316",
    totalcollection: "f317",
    latestissuedate: "f319",
    additionalissuelistingdate: "f318",
    offer_code: "f12",
    offer_name_abbr: "f14",
    offer_price: "f320",
    expect_offer_shares: "f321",
    offer_type: "f139",
    offer_start_date: "f322",
    offer_end_date: "f323",
    offer_state: "f308",
  }

  function handlerCmd(cmd: string | undefined, cmd1: string, cmd2: string) {
    let cmdstr = cmd1
    if (cmd) cmdstr = cmd2 + cmd.replace("_TB", "")
    return cmdstr
  }

  // 获取行情数据和页码
async function getQuoteData(url: string) {
  try {

    let back = await $.ajax({
      url: `${quoteapi}${url}`,
      dataType:'jsonp',
      timeout: 4000
    })

    if (back.data?.diff instanceof Array) {
      return {
        total: back.data.total ,
        result: back.data.diff
      }
    }
  } catch (error:any) {
    Logger_Error(error)
  }
  return {
    total: 0,
    result: []
  }
}

async function getQuoteZSData(url: string) {
  try {

    let back = await $.ajax({
      url: `${quoteapi}${url}`,
      dataType:'jsonp',
      timeout: 4000
    })

    if (JSON.stringify(back.data) != "{}") {
      return {
        total: 1 ,
        result: [back.data]
      }
    }
  } catch (error:any) {
    Logger_Error(error)
  }
  return {
    total: 0,
    result: []
  }
}

 /**
  * 领涨股
  * @returns 
  */
 export async function getLZB():Promise<Array<any>>{
   try{
     let back = await $.ajax({
       url:`${quoteapi}/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f3&po=1&fs=m:0+t:81+s:!2048&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`,
       dataType:'jsonp'
     })
 
     if(back?.data?.diff instanceof Array){
       return back.data.diff
     }
 
     return []
   }
   catch(error){
     console.error(error)
   }
 
   return []
 }
 
 /**
  * 活跃榜
  * @returns 
  */
 export async function getHYB():Promise<Array<any>> {
   try{
     let back = await $.ajax({
       url:`${quoteapi}/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f5&po=1&fs=m:0+t:81+s:!2048&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`,
       dataType:'jsonp'
     })
 
     if(back?.data?.diff instanceof Array){
       return back.data.diff
     }
 
     return []
   }catch(error){
     console.error(error)
   }
 
   return []
 }
 
 /**
  * 获取一组代码的行情
  * @param scs 
  * @returns 
  */
 export async function getZXGQuote(scs:Array<string>):Promise<Array<any>>{
   try{
 
     let back = await $.ajax({
       url: `${quoteapi}/api/qt/ulist.np/get?ut=${ut}&fltt=1&invt=2&fields=f1,f2,f3,f4,f5,f6,f8,f9,f12,f13,f14,f15,f16,f17,f18,f20,f21,f23,f111,f152,f350,f351,f352&secids=${scs.join(",")}&cb=?`,
       dataType:'jsonp'
     })
 
 
     if(back?.data?.diff instanceof Array){
       return back.data.diff
     }
 
     return []
   }catch(error){
     console.error(error)
   }
 
   return []
 }

 /**
  * 获取挂牌公司数据
  * @param sortType 
  * @param sortRule 
  * @param page 
  * @param pageSize 
  * @param cmd 
  * @returns 
  */
 export async function getGPGS(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+s:!2048', 'm:0+t:81+s:!2048+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152,f324&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

 /**
  * 获取创新层数据
  * @param sortType 
  * @param sortRule 
  * @param page 
  * @param pageSize 
  * @param cmd 
  * @returns 
  */
export async function getCXC(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
  let cmdstr = handlerCmd(cmd, 'm:0+t:81+s:512', 'm:0+t:81+s:512+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152,f324&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}


/**
  * 获取基础层数据
  * @param sortType 
  * @param sortRule 
  * @param page 
  * @param pageSize 
  * @param cmd 
  * @returns 
  */
 export async function getJCC(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+s:256', 'm:0+t:81+s:256+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152,f324&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

/**
  * 获取集合竞价交易
  * @param sortType 
  * @param sortRule 
  * @param page 
  * @param pageSize 
  * @param cmd 
  * @returns 
  */
 export async function getJHJJJY(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+s:128', 'm:0+t:81+s:128+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152,f324&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

/**
  * 获取做市交易列表
  * @param sortType 
  * @param sortRule 
  * @param page 
  * @param pageSize 
  * @param cmd 
  * @returns 
  */
 export async function getZSJY(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+s:32', 'm:0+t:81+s:32+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152,f324&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}



/**
 * 获取新挂牌股票
 * @param sortType 
 * @param sortRule 
 * @param page 
 * @param pageSize 
 * @param cmd 
 * @returns 
 */
export async function getXGPGP(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+e:80+s:4096', 'm:0+t:81+e:80+s:4096+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f26,f111,f152,f293,f304,f310,f311,f312,f324&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

/**
 * 获取发行股票
 * @param sortType 
 * @param sortRule 
 * @param page 
 * @param pageSize 
 * @param cmd 
 * @returns 
 */
 export async function getFXGP(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+e:85', 'm:0+t:81+e:85+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f5,f12,f13,f14,f111,f139,f152,f293,f305,f306,f307,f308,f309,f310,f312,f313,f314&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}


/**
 * 获取增发股票
 * @param sortType 
 * @param sortRule 
 * @param page 
 * @param pageSize 
 * @param cmd 
 * @returns 
 */
 export async function getZFGP(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+s:8192', 'm:0+t:81+s:8192+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f26,f111,f152,f293,f304,f310,f311,f312,f315,f316,f317,f318,f319&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}


/**
 * 获取要约收购/回购
 * @param sortType 
 * @param sortRule 
 * @param page 
 * @param pageSize 
 * @param cmd 
 * @returns 
 */
 export async function getYYSGHG(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+e:82,m:0+t:81+e:83', 'm:0+t:81+e:82,m:0+t:81+e:83+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f12,f13,f14,f111,f139,f152,f305,f306,f307,f308,f319,f320,f321,f322,f323&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}
 
/**
 * 获取neeq重点行情指数
 * @returns 
 */
 export async function getQuoteZS_SBZS():Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let back = await getQuoteZSData(
      `/api/qt/stock/get?ut=${ut}&fltt=1&invt=2&fields=f43,f44,f45,f46,f47,f48,f57,f58,f59,f60,f107,f116,f152,f162,f169,f170,f181,f1,f3,f4&secid=0.899002&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

/**
 * 获取neeq重点行情指数
 * @returns 
 */
 export async function getQuoteZS_SBCZ():Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let back = await getQuoteZSData(
      `/api/qt/stock/get?ut=${ut}&fltt=1&invt=2&fields=f43,f44,f45,f46,f47,f48,f57,f58,f59,f60,f107,f116,f152,f162,f169,f170,f181&secid=0.899001&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

/**
 * 三板成指
 * @param sortType 
 * @param sortRule 
 * @param page 
 * @param pageSize 
 * @param cmd 
 * @returns 
 */
 export async function getSBCZ(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'b:MK0814+s:!2048', 'b:MK0814+s:!2048+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152,f324&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

/**
 * 三板做市
 * @param sortType 
 * @param sortRule 
 * @param page 
 * @param pageSize 
 * @param cmd 
 * @returns 
 */
 export async function getSBZS(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'b:MK0601+s:!2048', 'b:MK0601+s:!2048+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152,f324&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

/**
 * 优先股
 * @param sortType 
 * @param sortRule 
 * @param page 
 * @param pageSize 
 * @param cmd 
 * @returns 
 */
 export async function getPreferredStock(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+t:81+e:81', 'm:0+t:81+e:81+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}

/**
 * 两网及退市
 * @param sortType 
 * @param sortRule 
 * @param page 
 * @param pageSize 
 * @param cmd 
 * @returns 
 */
 export async function getLWTS(sortType: string, sortRule: string, page: number, pageSize: number, cmd: string | undefined):Promise<{
  result:Array<any>,
  total:number
}>{
  try{
    let cmdstr = handlerCmd(cmd, 'm:0+s:3', 'm:0+s:3+i:0.')
    let back = await getQuoteData(
      `/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=${page}&pz=${pageSize}&fid=${mapSortType[sortType as keyof typeof mapSortType]}&po=${sortRule}&fs=${cmdstr}&fields=f1,f2,f3,f4,f5,f6,f9,f12,f13,f14,f15,f16,f17,f18,f20,f111,f152&cb=?`
    )
    return back

  }catch(error){
    console.error(error)
  }
  return {
    result:[],
    total:0
  }
}


// 行情中心--交易排名

// 全部涨幅榜
export async function getIndexZFB():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f3&po=1&fs=m:0+t:81+s:!2048&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 全部跌幅榜
export async function getIndexDFB():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f3&po=0&fs=m:0+t:81+s:!2048&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 全部成交额
export async function getIndexCJE():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f6&po=1&fs=m:0+t:81+s:!2048&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 全部成交量
export async function getIndexCJL():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f5&po=1&fs=m:0+t:81+s:!2048&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 创新层涨幅榜
export async function getCXCZFB():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f3&po=1&fs=m:0+t:81+s:512&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 创新层跌幅榜
export async function getCXCDFB():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f3&po=0&fs=m:0+t:81+s:512&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 创新层成交额
export async function getCXCCJE():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f6&po=1&fs=m:0+t:81+s:512&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 创新层成交量
export async function getCXCCJL():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f5&po=1&fs=m:0+t:81+s:512&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}


// 基础层涨幅榜
export async function getJCCZFB():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f3&po=1&fs=m:0+t:81+s:256&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 基础层跌幅榜
export async function getJCCDFB():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f3&po=0&fs=m:0+t:81+s:256&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 基础层成交额
export async function getJCCCJE():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f6&po=1&fs=m:0+t:81+s:256&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}

// 基础层成交量
export async function getJCCCJL():Promise<{
  result:Array<any>,
  total:number
}>{
  return getQuoteData(`/api/qt/clist/get?ut=${ut}&fltt=1&invt=2&np=1&pn=1&pz=6&fid=f5&po=1&fs=m:0+t:81+s:256&fields=f1,f2,f3,f4,f5,f6,f12,f13,f14,f111,f152&cb=?`)
}