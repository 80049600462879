import React, {useEffect, useState} from 'react'
import { GetQuoteIcon, ListStockF10Link, ListStockName, ListStockNewPrice, ListStockTradeVolume, ListStockUpDownRate } from '.';
import { getLZB } from '../../modules/data/quote/web';

// 首页领涨榜

export function IndexLZB(props:{
  at:string // 标记是首页的榜还是资讯_直播下的榜，两者展示上有些区别
}){

  const [data, SetData] = useState([] as Array<any>)

  useEffect(()=>{
    ;(async()=>{
      let data = await getLZB()
      SetData(data)
    })();
  }, [])

  if(data.length == 0){
    return (
      <div className="w285 fl">
        <div className="Pannel clearfix">
            <div className="tabList">
                <div className="subTit custom">
                    <ul>
                        <li className="first">名称</li>
                        <li className="w70 txtRight">最新价</li>
                        <li className="w70 txtRight">成交额(万)</li>
                        <li className="w70 txtRight">涨跌幅</li>
                    </ul>
                </div>
                <div className="data custom clearfix">
                  {
                    Array.from(new Array(6)).map((v, index) =>
                      <ul key={index} className="clearfix">
                        <li className="first">
                        </li>
                        <li className="w50"></li>
                        <li className="w50 last"></li>
                      </ul>
                    )
                  }
                </div>
            </div>
        </div>
    </div>
    )    
  }

  return (
    <div className={props.at == "index" ? "w285 fl" : "w290 fl"}>
      <div className="Pannel clearfix">
          <div className="tabList">
              <div className="subTit custom">
                  <ul>
                      <li className={props.at == "index" ? "first" : "first w100 textCenter"}>名称</li>
                      <li className={props.at == "index" ? "w70 txtRight" : "w50 textRight"}>最新价</li>
                      <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"}>成交额(万)</li>
                      <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"}>涨跌幅</li>
                  </ul>
              </div>
              <div className="data custom clearfix">
                {
                  data.map((v, index) =>
                    <ul key={index} className={ index % 2 != 0 ? "clearfix bg" : "clearfix"}>
                      <li className={props.at == "index" ? "first" : "first w100 textLeft"} >
                          {props.at == "index" &&<GetQuoteIcon data={v}></GetQuoteIcon>}
                          <ListStockF10Link data={v}>
                            <ListStockName data={v}></ListStockName>
                          </ListStockF10Link>
                      </li>
                      <li className={props.at == "index" ? "w70 txtRight" : "w50 textRight"} title={typeof v.f2 == 'string' ? v.f2 :(v.f2 / Math.pow(10, v.f1)).toFixed(v.f1)}>
                        <ListStockNewPrice data={v}></ListStockNewPrice>
                      </li>
                      <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"} title={ typeof v.f6 == 'string' ?  v.f6 :(v.f6 / 10000).toFixed(2)} >
                        <ListStockTradeVolume data={v}></ListStockTradeVolume>
                      </li>
                      <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"} title={ typeof v.f3 == 'string' ?  v.f3 : (v.f3 / Math.pow(10, v.f152)).toFixed(v.f152)}>
                        <ListStockUpDownRate data={v}></ListStockUpDownRate>
                      </li>
                    </ul>
                  )
                }
              </div>
          </div>
      </div>
  </div>
  ) 

}