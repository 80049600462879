import React, { useEffect, useState } from "react";
import { getCookie } from "../../modules/cookie";
import { getZXGQuote } from "../../modules/data/quote/web";
import { GetQuoteIcon, ListStockF10Link, ListStockName, ListStockNewPrice, ListStockTradeVolume, ListStockUpDownRate } from "../quote";

export function IndexZXG(props: {
  at: string // 标记是首页的榜还是资讯_直播下的榜，两者展示上有些区别
}) {

  const [zxgData, SetZxgData] = useState([] as Array<any>)

  useEffect(() => {
    ; (async () => {
      let backdata = await $.ajax({
        url: '/api/favor/list',
        dataType: 'json'
      })

      if (backdata.re && backdata.result.length > 0) {
        SetZxgData(backdata.result)
      }
    })();
  }, [])

  return <IndexQuoteZXG data={zxgData} at={props.at}></IndexQuoteZXG>
}

export function IndexQuoteZXG(props: {
  data: Array<string>,
  at: string
}) {

  const [quoteData, setQuoteData] = useState([] as Array<any>)

  const refreshIndex = async () => {
    if (props.data.length != 0) {
      let backdata = await getZXGQuote(props.data)
      // console.log(backdata)
      if (backdata.length > 0) {
        setQuoteData(backdata)
      }
    }
  }

  // // 获取cookie
  // const getCookie = (name:string) => {
  //   var mn = name + "=";
  //       var b, e;
  //       var co = document.cookie;
  //       if (mn == "=") {
  //           return co;
  //       }
  //       b = co.indexOf(mn);
  //       if (b < 0) {
  //           return "";
  //       }
  //       e = co.indexOf(";", b + name.length);
  //       if (e < 0) {
  //           return co.substring(b + name.length + 1);
  //       }
  //       else {
  //           return co.substring(b + name.length + 1, e);
  //       }
  // }

  const zxgtxt = () => {
    var utToken = getCookie("ut");
        var ctToken = getCookie("ct");
        var txt = "暂无数据"
        if (!utToken || !ctToken) {

            txt = "登录查看自选股！" 
        }else{
            txt = "暂无数据";
        }
        $(".zxgtxt").text(txt)
  }

  useEffect(() => {
    refreshIndex()
    zxgtxt()
    let timer = setInterval(() => {
      refreshIndex()
    }, 60000)
    return () => {
      clearInterval(timer)
    }
  }, [props.data])


  return (
    <>
      <div className={props.at == "index" ? "w285 fl" : "w290 fl"}>
        <div className="Pannel clearfix">
          <div className="tabList">
            <div className="subTit custom">
              <ul>
                <li className={props.at == "index" ? "first" : "first w100 textCenter"}>名称</li>
                <li className={props.at == "index" ? "w70 txtRight" : "w50 textRight"}>最新价</li>
                <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"}>涨跌幅</li>
                <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"}>成交额(万)</li>
              </ul>
            </div>
            <div className="data custom clearfix">
              {
                props.at == "index" && quoteData?.slice(0, 6).map((v, index) =>
                  <ul className={index % 2 != 0 ? "clearfix bg" : "clearfix"} key={index}>
                    <li className={props.at == "index" ? "first" : "first w100 textLeft"}>
                      {props.at == "index" && <GetQuoteIcon data={v}></GetQuoteIcon>}
                      <ListStockF10Link data={v}>
                        <ListStockName data={v}></ListStockName>
                      </ListStockF10Link>
                    </li>
                    <li className={props.at == "index" ? "w70 txtRight" : "w50 textRight"} title={typeof v.f2 == 'string' ? v.f2 : (v.f2 / Math.pow(10, v.f1)).toFixed(v.f1)}>
                      <ListStockNewPrice data={v}></ListStockNewPrice>
                    </li>
                    <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"} title={typeof v.f3 == 'string' ? v.f3 : (v.f3 / Math.pow(10, v.f152)).toFixed(v.f152)}>
                      <ListStockUpDownRate data={v}></ListStockUpDownRate>
                    </li>
                    <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"} title={typeof v.f6 == 'string' ? v.f6 : (v.f6 / 10000).toFixed(2)}>
                      <ListStockTradeVolume data={v}></ListStockTradeVolume>
                    </li>
                  </ul>)

              }
              {
                props.at != "index" && quoteData.map((v, index) =>
                  <ul className={index % 2 != 0 ? "clearfix bg" : "clearfix"} key={index}>
                    <li className={props.at == "index" ? "first" : "first w100 textLeft"}>
                      {props.at == "index" && <GetQuoteIcon data={v}></GetQuoteIcon>}
                      <ListStockF10Link data={v}>
                        <ListStockName data={v}></ListStockName>
                      </ListStockF10Link>
                    </li>
                    <li className={props.at == "index" ? "w70 txtRight" : "w50 textRight"} title={typeof v.f2 == 'string' ? v.f2 : (v.f2 / Math.pow(10, v.f1)).toFixed(v.f1)}>
                      <ListStockNewPrice data={v}></ListStockNewPrice>
                    </li>
                    <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"} title={typeof v.f3 == 'string' ? v.f3 : (v.f3 / Math.pow(10, v.f152)).toFixed(v.f152)}>
                      <ListStockUpDownRate data={v}></ListStockUpDownRate>
                    </li>
                    <li className={props.at == "index" ? "w70 txtRight" : "w70 textRight"} title={typeof v.f6 == 'string' ? v.f6 : (v.f6 / 10000).toFixed(2)}>
                      <ListStockTradeVolume data={v}></ListStockTradeVolume>
                    </li>
                  </ul>)
              }
              {(quoteData == null || quoteData.length <= 0) &&
                <div>
                  <div style={{ height: '200px', lineHeight: '200px', fontSize: '20px', textAlign: 'center' }} className="zxgtxt">
                  </div>
                </div>
              }
            </div>
          </div>
          <div style={{ clear: 'both' }}></div>
        </div>
      </div>
    </>
  )
}