/**
 * 日志记录
 */

import dayjs from "dayjs"


/**
 * 记录错误日志
 * @param error 
 */
export function Logger_Error(error:any, addinfo?: any){

  //如果是axios报错
  if(error?.config?.url){
    // let newerror = new Error('[' + dayjs().format('YYYY-MM-DD HH:mm:ss') + '] ' + error.message) as any
    // newerror.info = {
    //   time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    //   method: error.config.method,
    //   url: error.config.url,
    //   data: error.config.data
    // }
    console.error({
      ...addinfo,
      message: 'AxiosError ' + error.message,
      time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      method: error.config.method,
      url: error.config.url,
      data: error.config.data
    })
  }
  else{
    error.info = {
      ...addinfo,
      time: dayjs().format('YYYY-MM-DD HH:mm:ss')
    }  
    console.error(error)  
  }
}