/**
 * 文本相关
 */

import dayjs from "dayjs"
import React from "react"

/**
 * 数字小数位数格式化显示
 * @param input 输入
 * @param tofixed 小数位数
 * @param nonumber 不是数字的替代
 * @param hz 后缀 
 * @returns 
 */
export function NumberToFixed(input: any, tofixed = 2, nonumber = '-', hz = '') {

  if (isNaN(parseFloat(input))) {
    return nonumber
  }

  return parseFloat(input).toFixed(tofixed) + hz
}

/**
 * 数字除以10000，并保留两位小数
 * @param input 输入
 * @param nonumber 不是数字的替代
 * @returns 
 */
export function moneyToShow(input: any, nonumber = '-') {
  if (isNaN(parseFloat(input))) {
    return nonumber
  }
  let number = parseFloat(input)
  return (number / 10000).toFixed(2)
}

/**
 * 整数千分位显示
 * @param input 
 */
export function NumberLocaleString(input: any, nonumber = '-') {
  if (isNaN(parseInt(input))) {
    return nonumber
  }

  return parseInt(input).toLocaleString()
}

/**
 * 数字千分位显示，并保留两位小数
 * @param input 
 */
export function NumberLocaleStringFixedTwo(input: any, nonumber = '-') {
  if (isNaN(parseInt(input))) {
    return nonumber
  }
  let number = parseFloat(input).toFixed(2)
  return number.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}

/**
 * 数字千分位显示，并保留两位小数,百分比显示
 * @param input 
 */
export function NumberRatioFixedTwo(input: any, nonumber = '-') {
  if (isNaN(parseFloat(input))) {
    return nonumber
  }
  let number = parseFloat(input).toFixed(2)
  return number.replace(/(\d)(?=(\d{3})+\.)/g, '$1,') + '%'
}

/**
 * 删除字符串结尾特定字符
 * @param char:要删除的特定字符 str待处理的字符
 */
export function trimStrEnd(str: string, char: string) {
  if (str && str[str.length - 1] == char) {
    let reStr = str.slice(0, str.length - 1)
    return reStr
  } else {
    return str
  }
}

/**
 * 替换字符串的特殊字符
 * @param str 
 * @returns 
 */
export function replaceStr(str: string) {
  return str.replace(/<[^>]*>/gi, "")
}

/**
 * 根据股票代码去掉后缀
 * @param code 
 */
export function splitCodeRule(code: string) {
  if (code) {
    let arr = code?.split(".")
    if (arr != null && arr[0] != null) {
      return arr[0]
    }
  }
  return code
}

/**
 * 格式化日期字符串
 * @param date  2023/2/22 0:00:00
 * @returns 
 */
export function formatStrToDate(date: string) {
  try {
    if (date) {
      let d = dayjs(date).toDate()
      let formatD = dayjs(d).format("YYYY-MM-DD")
      return formatD
    }
    return "-"
  } catch (error) {
    console.log(error)
    return "-"
  }

}


/**
 * 格式化数据金额：万，亿
 * @param num 
 * @returns 
 */
export function changeMoney(num: any) {
  if (typeof (num) == 'string') {
    return {
      num: num,
      unit: ""
    }
  }
  num = Number(num)
  if (num <= 1) return {
    num,
    unit: ''
  };
  var moneyUnits = ["", "万", "亿", "万亿"]
  var dividend = 10000;
  var curentNum = num;
  //转换数字 
  var curentUnit = moneyUnits[0];
  //转换单位 
  for (var i = 0; i < 4; i++) {
    curentUnit = moneyUnits[i]
    if (strNumSize(curentNum) < 5) {
      break;
    }
    curentNum = curentNum / dividend
  }
  var m = {
    num: 0,
    unit: ""
  }

  m.num = curentNum?.toFixed(2) as any
  // 万元单位以下不需要保留小数点后两位
  if (!curentUnit) {
    m.num = curentNum?.toFixed(0) as any
  }
  m.unit = curentUnit;
  return m;
}

/**
 * 计算数值的最大单位
 * @param tempNum 
 * @returns 
 */
export function strNumSize(tempNum: number) {
  var stringNum = tempNum.toString()
  var index = stringNum.indexOf(".")
  var newNum = stringNum;
  if (index != -1) {
    newNum = stringNum.substring(0, index)
  }
  return newNum.length
}

/**
 * 报告年度
 * @returns 
 */
export function getRepYear() {
  let curYear = dayjs().get('year')
  let yearArr = []
  for (let i = curYear; i >= curYear - 4; i--) {
    yearArr.push(i)
  }
  return yearArr
}


// 数字格式标识的日期转为字符串
export function dateIntegerToDate(input: any) {
  if (typeof input == 'string') {
    return input
  }

  input = input?.toString()
  let res = input?.substring(0, 4) + '-' + input?.substring(4, 6) + '-' + input?.substring(6, 8)
  return res
}


/**
 * 截取字符串
 * @param input 
 * @param start 开始下标
 * @param end 结束下标
 * @param hz 后缀
 * @returns 
 */
export function subStr(input: any, start: number, end: number, hz = '') {
  let res = ""
  if (typeof input != 'string') {
    return input
  }

  if (hz != '') {
    if (input?.length > end) {
      res = input.substring(start, end) + hz
    } else {
      res = input
    }
  } else {
    res = input.substring(start, end)
  }
  return res
}


/**
 * 字段为空时特殊处理
 * @param data 
 * @param def 
 * @returns 
 */
export function emptyProcess(data: any, def?: any) {
  if (def == undefined || def == "") def = "-"

  return data == "" ? def : data
}


export function hideContact(str: string) {
  if (str.length > 7) {
    var str1 = str.slice(0, 3)
    var str2 = str.slice(7)
    return str1 + '****' + str2
  } else {
    var str1 = str.slice(0, str.length - 4)
    return str1 + '****';
  }
}

/**
 * 格式化url中查询参数,返回对象格式
 * @param url 
 * @returns 
 */
// export function getPramas(url:string){
//   var json = {} as any
//   var regExp = /[\?\&](\w+)(=?)(\w*)/g;

//   do{
//     var arr = regExp.exec(url)
//     if(arr){
//       var key = arr[1]
//       var value = arr[3] as any
//       if(!arr[2]){
//         value = undefined
//       }
//       json[key] = value
//     }
//   }while(arr)
//   return json
// }

export function getParams(key:string) {
	var temData = new URLSearchParams(window.location.search);
	return temData.get(key)
}


// 一维数组转二维数组
export function arr1to2(arr:Array<any>, num:number) {
  var arr2 = [];
  let len = arr.length;
  for (let i = 0, j = 0; i < len; i += num, j++) {
    arr2[j] = arr.splice(0, num);
  }
  return arr2;
}