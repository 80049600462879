import React from 'react'
import ReactDOM from 'react-dom'

import { IndexLZB } from '../components/quote/index_lzb'
import { IndexHYB } from '../components/quote/index_hyb'
import { IndexZXG } from '../components/favor/index_favor'


ReactDOM.render(
  <IndexLZB at="index"></IndexLZB>,
  document.getElementById('index_lzb')
)


ReactDOM.render(
  <IndexHYB at="index"></IndexHYB>,
  document.getElementById('index_hyb')
)

ReactDOM.render(
  <IndexZXG at="index"></IndexZXG>,
  document.getElementById('zxglist_new')
)
