import React from 'react'
import { deleteZXG } from '../../modules/data/favor/node'
import { formatMoney } from '../../modules/txt'
import { changeMoney, dateIntegerToDate, NumberToFixed } from '../shared/txt'

/**
 * 列表_股票名称
 */
export function ListStockName(props: {
  data: any
}) {
  return props.data.f14
}

/**
 * 列表_股票代码
 */
export function ListStockCode(props: {
  data: any
}) {
  return props.data.f12
}

/**
 * 列表_股票F10链接
 */
export function ListStockF10Link(props: {
  data: any,
  children: any
}) {
  return (
    <a href={`/F10/${props.data.f12}.html`} target="_blank">
      {props.children}
    </a>
  )
}

/**
 * 列表_股票quotecenter链接
 */
export function ListStockQCLink(props: {
  data: any,
  children?: any,
  codeziduan: string
}) {
  // if (props.at == "发行股票和要约回购") {
  //   return (
  //     <a href={`/QuoteCenter/${props.data.f305}.html`} target="_blank">
  //       {props.children}
  //     </a>
  //   )

  // }
  return (
    <a href={`/QuoteCenter/${props.data[props.codeziduan]}.html`} target="_blank">
      {props.children}
    </a>
  )
}



/**
 * 列表_最新价
 */
export function ListStockNewPrice(props: {
  data: any
}) {

  if (typeof props.data.f2 == 'string') {
    return <span>{props.data.f2}</span>
  }

  let res = (props.data.f2 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  if (isNaN(props.data.f4)) {

  }
  else if (props.data.f4 > 0) {
    color = 'price_up'
  }
  else if (props.data.f4 < 0) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )
}


/**
 * 列表_成交额
 * @param props 
 */
export function ListStockTradeVolume(props: {
  data: any
}) {
  if (typeof props.data.f6 == 'string') {
    return <span>{props.data.f6}</span>
  }
  let res = (props.data.f6 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )
}

/**
 * 列表_成交量
 * @param props 
 */
export function ListStockTradeCount(props: {
  data: any
}) {
  if (typeof props.data.f5 == 'string') {
    return <span>{props.data.f5}</span>
  }
  let res = (props.data.f5 * 100 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )
}

/**
 * 列表_涨跌幅
 * @param props 
 * @returns 
 */
export function ListStockUpDownRate(props: {
  data: any
}) {
  if (typeof props.data.f3 == 'string') {
    return <span>{props.data.f3}</span>
  }

  let res = (props.data.f3 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  if (isNaN(props.data.f3)) {

  }
  else if (props.data.f4 > 0) {
    color = 'price_up'
  }
  else if (props.data.f4 < 0) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}%</span>
  )
}

/**
 * 表格列表_涨跌幅
 * @param props 
 * @returns 
 */
export function TableListStockUpDownRate(props: {
  data: any
}) {
  if (typeof props.data.f3 == 'string') {
    return <span>{props.data.f3}</span>
  }

  let res = (props.data.f3 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  if (isNaN(props.data.f3)) {

  }
  else if (props.data.f4 > 0) {
    color = 'price_up'
  }
  else if (props.data.f4 < 0) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )
}

/**
 * 列表涨跌额
 * @param props 
 * @returns 
 */
export function ListStockUpDown(props: {
  data: any
}) {
  if (typeof props.data.f4 == 'string') {
    return <span>{props.data.f4}</span>
  }

  let res = (props.data.f4 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  if (isNaN(props.data.f4)) {

  }
  else if (props.data.f4 > 0) {
    color = 'price_up'
  }
  else if (props.data.f4 < 0) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )
}

/**
 * 列表昨收价
 * @param props 
 */
export function ListYesterdayPrice(props: {
  data: any
}) {
  if (typeof props.data.f18 == 'string') {
    return <span>{props.data.f18}</span>
  }

  let res = (props.data.f18 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'
  if (isNaN(props.data.f18)) {
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表今开价
 * @param props 
 */
export function ListTodayPrice(props: {
  data: any,
  color?:string
}) {
  if (typeof props.data.f17 == 'string') {
    return <span>{props.data.f17}</span>
  }

  let res = (props.data.f17 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  if (isNaN(props.data.f17)) {
    return <span>-</span>
  }
  else if (props.data.f17 - props.data.f18 > 0) {
    color = 'price_up'
  }
  else if (props.data.f17 - props.data.f18 < 0) {
    color = 'price_down'
  }

  return (
    props.color ? <span style={{color:props.color}}>{res}</span> :
    <span className={color}>{res}</span>
  )

}

/**
 * 列表最高价
 * @param props 
 */
export function ListHighPrice(props: {
  data: any,
  color?:string
}) {
  if (typeof props.data.f15 == 'string') {
    return <span>{props.data.f15}</span>
  }

  let res = (props.data.f15 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  if (isNaN(props.data.f15)) {
    return <span>-</span>
  }
  else if (props.data.f15 - props.data.f18 > 0) {
    color = 'price_up'
  }
  else if (props.data.f15 - props.data.f18 < 0) {
    color = 'price_down'
  }

  return (
    props.color ? <span style={{color:props.color}}>{res}</span> :
    <span className={color}>{res}</span>
  )

}

/**
 * 列表最低价
 * @param props 
 */
export function ListLowPrice(props: {
  data: any,
  color?:string
}) {
  if (typeof props.data.f16 == 'string') {
    return <span>{props.data.f16}</span>
  }

  let res = (props.data.f16 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  if (isNaN(props.data.f16)) {
    return <span>-</span>
  }
  else if (props.data.f16 - props.data.f18 > 0) {
    color = 'price_up'
  }
  else if (props.data.f16 - props.data.f18 < 0) {
    color = 'price_down'
  }

  return (
    props.color ? <span style={{color:props.color}}>{res}</span> :
    <span className={color}>{res}</span>
  )

}

/**
 * 列表市盈率
 * @param props 
 */
export function ListPERation(props: {
  data: any
}) {
  if (typeof props.data.f9 == 'string') {
    return <span>{props.data.f9}</span>
  }

  let res = (props.data.f9 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表总市值
 * @param props 
 */
export function ListMarketValue(props: {
  data: any
}) {
  if (typeof props.data.f20 == 'string') {
    return <span>{props.data.f20}</span>
  }

  let res = (props.data.f20 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表上市至今涨跌幅
 * @param props 
 */
export function ListPriceChangePercentSinceListing(props: {
  data: any
}) {
  if (typeof props.data.f304 == 'string') {
    return <span>{props.data.f304}</span>
  }

  let res = (props.data.f304 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  if (isNaN(props.data.f304)) {

  }
  else if (props.data.f304 > 0) {
    color = 'price_up'
  }
  else if (props.data.f304 < 0) {
    color = 'price_down'
  }


  return (
    <span className={color}>{res}</span>
  )

}


/**
 * 列表股票发行价
 * @param props 
 */
export function ListStockListingPrice(props: {
  data: any
}) {
  if (typeof props.data.f293 == 'string') {
    return <span>{props.data.f293}</span>
  }

  let res = (props.data.f293 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票发行总数
 * @param props 
 */
export function ListStockListingNum(props: {
  data: any
}) {
  if (typeof props.data.f310 == 'string') {
    return <span>{props.data.f310}</span>
  }

  let res = (props.data.f310 * 100 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票涨停
 * @param props 
 */
 export function ListStockZTPrice(props: {
  data: any
}) {
  if (typeof props.data.f350 == 'string') {
    return <span>{props.data.f350}</span>
  }

  let res = (props.data.f350 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  if(isNaN(props.data.f350)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )
}

/**
 * 列表股票跌停
 * @param props 
 */
 export function ListStockDTPrice(props: {
  data: any
}) {
  if (typeof props.data.f351 == 'string') {
    return <span>{props.data.f351}</span>
  }

  let res = (props.data.f351 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  if(isNaN(props.data.f351)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )
}

/**
 * 列表股票均价
 * @param props 
 */
 export function ListStockAvgPrice(props: {
  data: any
}) {
  if (typeof props.data.f352 == 'string') {
    return <span>{props.data.f352}</span>
  }

  let res = (props.data.f352 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  if(isNaN(props.data.f352)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )
}

/**
 * 列表股票总市值
 * @param props 
 */
 export function ListStockTotalValue(props: {
  data: any
}) {
  if (typeof props.data.f20 == 'string') {
    return <span>{props.data.f20}</span>
  }

  let res = formatMoney(props.data.f20,2)

  let color = 'price_draw'
  if(isNaN(props.data.f20)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票流通市值
 * @param props 
 */
 export function ListStockCirculationValue(props: {
  data: any
}) {
  if (typeof props.data.f21 == 'string') {
    return <span>{props.data.f21}</span>
  }

  let res = formatMoney(props.data.f21,2)

  let color = 'price_draw'

  if(isNaN(props.data.f21)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票换手率
 * @param props 
 */
 export function ListStockTurnOverRate(props: {
  data: any
}) {
  if (typeof props.data.f8 == 'string') {
    return <span>{props.data.f8}</span>
  }

  let res = (props.data.f8 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  if(isNaN(props.data.f8)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}%</span>
  )

}

/**
 * 列表股票市盈率（动态）
 * @param props 
 */
 export function ListStockSYLDynamic (props: {
  data: any
}) {
  if (typeof props.data.f9 == 'string') {
    return <span>{props.data.f9}</span>
  }

  let res = (props.data.f9 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  if(isNaN(props.data.f9)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票市净率
 * @param props 
 */
 export function ListStockSJL (props: {
  data: any
}) {
  if (typeof props.data.f23 == 'string') {
    return <span>{props.data.f23}</span>
  }

  let res = (props.data.f23 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  if(isNaN(props.data.f23)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票金额
 * @param props 
 */
 export function ListStockMoney(props: {
  data: any
}) {
  if (typeof props.data.f5 == 'string') {
    return <span>{props.data.f5}</span>
  }

  let res = NumberToFixed(props.data.f5)

  let color = 'price_draw'

  if(isNaN(props.data.f5)){
    return <span>-</span>
  }
  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票发行市盈率
 * @param props 
 */
export function ListStockSYL(props: {
  data: any
}) {
  if (typeof props.data.f311 == 'string') {
    return <span>{props.data.f311}</span>
  }

  let res = (props.data.f311 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  if (isNaN(props.data.f311)) {

  }
  else if (props.data.f311 > 0) {
    color = 'price_up'
  }
  else if (props.data.f311 < 0) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票上市日期
 * @param props 
 */
export function ListStockListingDate(props: {
  data: any
}) {
  if (typeof props.data.f312 == 'string') {
    return <span>{props.data.f312}</span>
  }

  let res = dateIntegerToDate(props.data.f312)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票增发价格
 * @param props 
 */
export function ListStockZFPrice(props: {
  data: any
}) {
  if (typeof props.data.f315 == 'string') {
    return <span>{props.data.f315}</span>
  }

  let res = (props.data.f315 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票增发数量
 * @param props 
 */
export function ListStockZFNum(props: {
  data: any
}) {
  if (typeof props.data.f316 == 'string') {
    return <span>{props.data.f316}</span>
  }

  let res = (props.data.f316 * 100 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票募集总额
 * @param props 
 */
export function ListStockMJTotalCount(props: {
  data: any
}) {
  if (typeof props.data.f317 == 'string') {
    return <span>{props.data.f317}</span>
  }

  let res = (props.data.f317 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票最新发行日期
 * @param props 
 */
export function ListStockLastestDate(props: {
  data: any
}) {
  if (typeof props.data.f319 == 'string') {
    return <span>{props.data.f319}</span>
  }

  let res = dateIntegerToDate(props.data.f319)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表股票增方上市日期
 * @param props 
 */
export function ListStockZFDate(props: {
  data: any
}) {
  if (typeof props.data.f318 == 'string') {
    return <span>{props.data.f318}</span>
  }

  let res = dateIntegerToDate(props.data.f318)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 发行股票页面代码
 * 和上面ListStockCode不一样
 * @param props 
 */
export function ListStockFXGPCode(props: {
  data: any
}) {
  if (typeof props.data.f305 == 'string') {
    return <span>{props.data.f305}</span>
  }

  let res = props.data.f305

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 发行股票页面名称
 * @param props 
 */
export function ListStockFXGPName(props: {
  data: any
}) {
  if (typeof props.data.f307 == 'string') {
    return <span>{props.data.f307}</span>
  }

  let res = props.data.f307

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 发行价格
 * @param props 
 */
export function ListStockFXPrice(props: {
  data: any
}) {
  if (typeof props.data.f309 == 'string') {
    return <span>{props.data.f309}</span>
  }

  let res = (props.data.f309 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 要约价格
 * @param props 
 */
export function ListStockYYPrice(props: {
  data: any
}) {
  if (typeof props.data.f320 == 'string') {
    return <span>{props.data.f320}</span>
  }

  let res = (props.data.f320 / Math.pow(10, props.data.f1)).toFixed(props.data.f1)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 拟要约数量
 * @param props 
 */
export function ListStockNYYNum(props: {
  data: any
}) {
  if (typeof props.data.f321 == 'string') {
    return <span>{props.data.f321}</span>
  }

  let res = (props.data.f321 * 100 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 要约方式
 * @param props 
 */
export function ListStockYYType(props: {
  data: any
}) {
  let res = "-"

  let stockType = props.data.f139
  if (stockType == 80) {
    res = "挂牌公司股票";
  }
  else if (stockType == 81) {

    res = "优先股";
  }
  else if (stockType == 82) {
    res = "要约收购";
  }
  else if (stockType == 83) {

    res = "要约回购";
  }
  else if (stockType == 85) {
    res = "发行股票";
  }
  else {
    res = "-";
  }
  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 要约开始日期
 * @param props 
 */
export function ListStockYYStartDate(props: {
  data: any
}) {
  if (typeof props.data.f322 == 'string') {
    return <span>{props.data.f322}</span>
  }

  let res = dateIntegerToDate(props.data.f322)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 要约结束日期
 * @param props 
 */
export function ListStockYYEndDate(props: {
  data: any
}) {
  if (typeof props.data.f323 == 'string') {
    return <span>{props.data.f323}</span>
  }

  let res = dateIntegerToDate(props.data.f323)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 要约状态
 * @param props 
 */
export function ListStockYYState(props: {
  data: any
}) {
  let res = "-"

  let stockType = props.data.f139
  let stockState = props.data.f308
  if (stockType == 80) {
    if (stockState & 8) res = "处于要约期";
    else if (stockState & 128) res = "不处于要约期";
  }
  else if (stockType == 81) {
    if (stockState & 32) res = "处于回售期";
    else if (stockState & 512) res = "不处于回售期";
    else if (stockState & 64) res = "允许转股";
    else if (stockState & 1024) res = "禁止转股";
  }
  else if (stockType == 82 || stockType == 83) {
    if (stockState & 8) res = "处于截止期间";
    else if (stockState & 128) res = "处于正常时间";
  }
  else if (stockType == 85) {
    if (stockState & 2048) res = "正常状态";
    else if (stockState & 4096) res = "首日挂牌";
    else if (stockState & 8192) res = "新增挂牌";
    else if (stockState & 16384) res = "询价阶段";
    else if (stockState & 32768) res = "申购阶段";
  }
  else {
    res = "-";
  }
  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 发行状态
 * @param props 
 */
export function ListStockFXState(props: {
  data: any
}) {
  let res = "-"
  let stockType = props.data.f139
  let sotckStatus = props.data.f308
  if (stockType == 80) {
    if (sotckStatus & 8) res = "处于要约期";
    else if (sotckStatus & 128) res = "不处于要约期";
  }
  else if (stockType == 81) {
    if (sotckStatus & 32) res = "处于回售期";
    else if (sotckStatus & 512) res = "不处于回售期";
    else if (sotckStatus & 64) res = "允许转股";
    else if (sotckStatus & 1024) res = "禁止转股";
  }
  else if (stockType == 82 || stockType == 83) {
    if (sotckStatus & 8) res = "处于截止期间";
    else if (sotckStatus & 128) res = "处于正常时间";
  }
  else if (stockType == 85) {
    if (sotckStatus & 2048) res = "正常状态";
    else if (sotckStatus & 4096) res = "首日挂牌";
    else if (sotckStatus & 8192) res = "新增挂牌";
    else if (sotckStatus & 16384) res = "询价阶段";
    else if (sotckStatus & 32768) res = "申购阶段";
  }
  else {
    res = "-";
  }

  let color = 'price_draw'

  return <span className={color}>{res}</span>;

}

/**
 * 网下申购下限
 * @param props 
 */
export function ListStockWXSGXX(props: {
  data: any
}) {
  if (typeof props.data.f313 == 'string') {
    return <span>{props.data.f313}</span>
  }

  let res = (props.data.f313 * 100 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 网下申购上限
 * @param props 
 */
export function ListStockWXSGSX(props: {
  data: any
}) {
  if (typeof props.data.f314 == 'string') {
    return <span>{props.data.f314}</span>
  }

  let res = (props.data.f314 * 100 / 10000).toFixed(2)

  let color = 'price_draw'

  return (
    <span className={color}>{res}</span>
  )

}

/**
 * 列表_图标
 * @param props 
 * @returns 
 */
export function GetQuoteIcon(props: {
  data: any
}) {
  let type = props.data.f111
  let icon1 = <></>
  let icon2 = <></>

  //基础层
  if ((type & 256) > 0) {
    icon1 = <em className="ji"></em>

  }
  //创新层
  else if ((type & 512) > 0) {
    icon1 = <em className="chuang"></em>

  }
  //精选层
  else if ((type & 2048) > 0) {
    icon1 = <em className="jingxuan"></em>

  }
  else {
    icon1 = <em className="kongge"></em>
  }


  //做市
  if ((type & 32) > 0) {
    icon2 = <em className="shi"></em>
  }
  //协议
  else if ((type & 16) > 0) {
    icon2 = <em className="jihe"></em>
  }
  //连续竞价
  else if ((type & 64) > 0) {
    icon2 = <em className="lian"></em>
  }
  //集合竞价
  else if ((type & 128) > 0) {
    icon2 = <em className="jihe"></em>
  }
  else {
    icon2 = <em className="kongge"></em>
  }

  return (
    <>
      {icon1} {icon2}
    </>
  )
}

/**
 * 重点行情指数
 * @param props 
 * @returns 
 */

// 指数代码
export function Quote_ZS_Code(props: {
  data: any
}){
  return <span>{props.data.f57}</span>
}

// 指数名称
export function Quote_ZS_Name(props: {
  data: any
}){
  return <span>{props.data.f58}</span>
}

// 个股行情指数最新价
export function StockLastestPrice(props: {
  data: any
}) {
  if (typeof props.data.f43 == 'string') {
    return <span>{props.data.f43}</span>
  }

  let res = (props.data.f43 / Math.pow(10, props.data.f59)).toFixed(props.data.f59)

  let color = 'price_draw'

  if (isNaN(props.data.f43)) {

  }
  else if (props.data.f169 > 0) {
    color = 'price_up'
  }
  else if (props.data.f169 < 0) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )

}

// 个股行情指数涨跌额

export function StockUpDown(props: {
  data: any
}) {
  if (typeof props.data.f169 == 'string') {
    return <span>{props.data.f169}</span>
  }

  let res = (props.data.f169 / Math.pow(10, props.data.f59)).toFixed(props.data.f59)

  let color = 'price_draw'
  if (isNaN(props.data.f169)) {

  }
  else if (props.data.f169 > 0) {
    color = 'price_up'
  }
  else if (props.data.f169 < 0) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )

}

// 个股行情指数涨跌幅
export function StockUpDownRate(props: {
  data: any
}) {
  if (typeof props.data.f170 == 'string') {
    return <span>{props.data.f170}</span>
  }

  let res = (props.data.f170 / Math.pow(10, props.data.f152)).toFixed(props.data.f152)

  let color = 'price_draw'

  if (isNaN(props.data.f170)) {

  }
  else if (props.data.f170 > 0) {
    color = 'price_up'
  }
  else if (props.data.f170 < 0) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}%</span>
  )
}

/**
 * 个股成交量
 * @param props 
 */
export function StockTradeVolume(props: {
  data: any
}) {
  if (typeof props.data.f47 == 'string') {
    return <span>{props.data.f47}</span>
  }

  // f47默认单位手
  let res = ((props.data.f47) * 100 / 10000).toFixed(2)

  return (
    <span>{res}</span>
  )
}

/**
 * 个股成交额
 * @param props 
 */
export function StockTradeMoney(props: {
  data: any
}) {
  if (typeof props.data.f48 == 'string') {
    return <span>{props.data.f48}</span>
  }

  let res = (props.data.f48 / 10000).toFixed(2)

  return (
    <span>{res}</span>
  )
}

/**
 * 昨收价
 * @param props 
 */
export function YesterdayMoney(props: {
  data: any
}) {
  if (typeof props.data.f60 == 'string') {
    return <span>{props.data.f60}</span>
  }

  let res = (props.data.f60 / Math.pow(10, props.data.f59)).toFixed(props.data.f59)

  return (
    <span>{res}</span>
  )
}

/**
 * 最高
 * @param props 
 */
export function YesterdayTop(props: {
  data: any
}) {
  if (typeof props.data.f44 == 'string') {
    return <span>{props.data.f44}</span>
  }

  let res = (props.data.f44 / Math.pow(10, props.data.f59)).toFixed(props.data.f59)

  let color = 'price_draw'

  if (isNaN(props.data.f44)) {

  }
  else if (parseFloat(props.data.f44) > parseFloat(props.data.f60)) {
    color = 'price_up'
  }
  else if (parseFloat(props.data.f44) < parseFloat(props.data.f60)) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )

}


/**
 * 最低
 * @param props 
 */
export function YesterdayLow(props: {
  data: any
}) {
  if (typeof props.data.f45 == 'string') {
    return <span>{props.data.f45}</span>
  }

  let res = (props.data.f45 / Math.pow(10, props.data.f59)).toFixed(props.data.f59)

  let color = 'price_draw'

  if (isNaN(props.data.f45)) {

  }
  else if (parseFloat(props.data.f45) > parseFloat(props.data.f60)) {
    color = 'price_up'
  }
  else if (parseFloat(props.data.f45) < parseFloat(props.data.f60)) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )

}


/**
 * 最近涨跌幅
 * @param props 
 */
export function LastChangePercent(props: {
  data: any,
  k: string
}) {
  let result = props.data[props.k]

  if (result && result.indexOf("%") > -1) {
    result = result.replace("%", "")
  }

  result = NumberToFixed(result)

  if (result < 0) {
    return <span style={{ color: '#007130' }}>{result}%</span>
  } else if (result > 0) {
    return <span style={{ color: '#e30000' }}>{result}%</span>
  } else {
    if (isNaN(result)) {
      return <span>-</span>
    } else {
      return <span>{result}%</span>
    }
  }
}


/**
 * 概念指数列表
 */

// 代码
export function Code_GNZS(props:{
  data:any
}){
  return (
    <span>{props.data.code}</span>
  )
}

// 名称
export function Name_GNZS(props:{
  data:any
}){
  let res = "-"
  if(props.data.name){
    res = props.data.name
  }
  return (
    <span>{res}</span>
  )
}

// 最新价
export function LastestPrice_GNZS(props:{
  data:any
}){
  if( typeof props.data.price == 'string'){
    return <span>{props.data.price}</span>
  }
  let num = NumberToFixed(props.data.price)
  
  let color = "price_draw"

  if(isNaN(props.data.priceChangePerSpecial)){

  }else if(props.data.priceChangePerSpecial > 0){
    color = "price_up"
  }else if(props.data.priceChangePerSpecial < 0){
    color = "price_down"
  }

  return (
    <span className={color}>{num}</span>
  )
}

// 涨跌幅
export function DownUpRate_GNZS(props:{
  data:any
}){
  if( typeof props.data.priceChangePerSpecial == 'string'){
    return <span>{props.data.priceChangePerSpecial}</span>
  }
  let num = NumberToFixed(props.data.priceChangePerSpecial)
  
  let color = "price_draw"

  if(isNaN(props.data.priceChangePerSpecial)){

  }else if(props.data.priceChangePerSpecial > 0){
    color = "price_up"
  }else if(props.data.priceChangePerSpecial < 0){
    color = "price_down"
  }

  return (
    <span className={color}>{num}</span>
  )
}

// 成交量（万股）
export function TradeVolume_GNZS(props:{
  data:any
}){
  if( typeof props.data.priceChange == 'string'){
    return <span>{props.data.priceChange}</span>
  }
  let num = NumberToFixed(props.data.priceChange / 100)
  
  let color = "price_draw"

  return (
    <span>{num}</span>
  )
}

// 成交额（万元）
export function TradeAmount_GNZS(props:{
  data:any
}){
  if( typeof props.data.amount == 'string'){
    return <span>{props.data.amount}</span>
  }
  let num = NumberToFixed(props.data.amount / 10000)
  
  let color = "price_draw"

  return (
    <span>{num}</span>
  )
}

// 最高价
export function HighestPrice_GNZS(props:{
  data:any
}){
  if (typeof props.data.high == 'string') {
    return <span>{props.data.high}</span>
  }

  let res = NumberToFixed(props.data.high)

  let color = 'price_draw'

  if (isNaN(props.data.high) || isNaN(props.data.pclose)) {

  }
  else if (parseFloat(props.data.high) > parseFloat(props.data.pclose)) {
    color = 'price_up'
  }
  else if (parseFloat(props.data.high) < parseFloat(props.data.pclose)) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )

}

// 最低价
export function LowestPrice_GNZS(props:{
  data:any
}){
  if (typeof props.data.low == 'string') {
    return <span>{props.data.low}</span>
  }

  let res = NumberToFixed(props.data.low)

  let color = 'price_draw'

  if (isNaN(props.data.low) || isNaN(props.data.pclose)) {

  }
  else if (parseFloat(props.data.low) > parseFloat(props.data.pclose)) {
    color = 'price_up'
  }
  else if (parseFloat(props.data.low) < parseFloat(props.data.pclose)) {
    color = 'price_down'
  }

  return (
    <span className={color}>{res}</span>
  )

}


/**
 * 昨收价
 * @param props 
 */
 export function YesterdayMoney_GNZS(props: {
  data: any
}) {
  if (typeof props.data.pclose == 'string') {
    return <span>{props.data.pclose}</span>
  }

  let res = NumberToFixed(props.data.pclose)

  return (
    <span>{res}</span>
  )
}

/**
 * 交易排名——成交量
 * @param props 
 * @returns 
 */
export function JYPM_Volume(props:{
  data:any
}){
  let res = changeMoney(props.data.f5).num + changeMoney(props.data.f5).unit

  return <span>{res}</span>
}

/**
 * 交易排名成交额
 * @param props 
 * @returns 
 */
export function JYPM_Amount(props:{
  data:any
}){
  let res = changeMoney(props.data.f6).num + changeMoney(props.data.f6).unit

  return <span>{res}</span>
}

