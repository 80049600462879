/**
 * 文字处理
 */

import dayjs from 'dayjs'

/**
 * 切断字符串
 * @param input 
 * @param length 
 * @param hz 
 * @returns 
 */
export function CutString(input: string, length: number, hz = '...'){
  if(input){
    if(input.length > length){
      return input.substring(0, length) + hz
    }
  }
  return input
}

/**
 * 格式化数据的日期 例如20170209 格式化成 2017-02-09
 * @param input 
 */
export function formatDataDate(input: string){
  if(input.length < 8) return input
  return input.substring(0, 4) + '-' + input.substring(4, 6) + '-' + input.substring(6, 8)
}

/**
 * 格式化数据的日期 例如	2017/2/9 0:00:00 格式化成 2017-02-09
 * @param input 
 */
export function formatDataDate2(input: string){
  try {
    return dayjs(input).format('YYYY-MM-DD')
  } catch (error) {
    
  }
  return '-'
}

/**
 * 格式化数据的日期 例如	2023-03-07 00:00:00 格式化成 2017年02月09日
 * @param input 
 */
export function formatDataDate3(input: string){
  try {
    return dayjs(input).format('YYYY年MM月DD日')
  } catch (error) {
    
  }
  return '-'
}

/**
 * 格式化数据百分比数字
 * @param input 
 */
export function formatDataPercent(input:string){
  let num = parseFloat(input)

  if(isNaN(num)) return '-'

  return num.toFixed(2) + '%'
}


/** 格式化金额 */
export function formatMoney(num:any, fixnum: number) {
  if(isNaN(num) || num === ''){
    return num
  }

  var hz = '';
  if(num >= 1000000000000||num <= -1000000000000){
    num = num / 1000000000000;
    hz = '万亿';
  }
  else if(num >= 100000000||num <= -100000000){
    num = num / 100000000;
    hz = '亿';
  }
  else if(num >= 10000||num <= -10000){
    num = num / 10000;
    hz = '万';
  }
  else{
    if(fixnum != undefined){
      return parseFloat(num).toFixed(fixnum)
    }
    return num;
  }

  if(fixnum != undefined){
    return parseFloat(num).toFixed(fixnum) + hz
  }
  return num + hz;  
}